import { useState, useEffect } from "react";
import { useCrypto } from "./providers/CryptoProvider";

import { useParams } from 'react-router-dom';
export const DownloadData = (props) => {
  const { encryptData, decryptData } = useCrypto();
  const [secret, set_secret] = useState();

  const [load_dataEncrypted, set_load_dataEncrypted] = useState("");

  const [load_dataDencrypted, set_load_dataDencrypted] = useState("");
  const [showEI, set_showEI] = useState(false);
  const { id } = useParams()

  const handleEncrypt = () => {
    if (!secret) {
      return;
    }
    const data = localStorage.getItem(`app_${id}`);
    set_load_dataEncrypted(encryptData(data, secret));
  };
  const handleDencrypt = () => {
    if (!secret) {
      return;
    }
   
    set_load_dataDencrypted(decryptData(load_dataEncrypted, secret));
  };

  const load = () => {
    if (!secret) {
        return;
      }

     const data = decryptData(load_dataEncrypted, secret)

     localStorage.setItem(`app_${id}`, data);
     window.location.reload();
  }
  useEffect(() => {
    set_secret(props.secret);
  }, [props]);
  return (
    <div>
      <button
        onClick={() => {
          set_showEI(!showEI);
        }}
      >
        export/import
      </button>
      {showEI && (
        <>
          <div>
            <button onClick={handleEncrypt}>Criptografar</button>
            <textarea value={load_dataEncrypted} onChange={(e) => set_load_dataEncrypted(e.target.value)}></textarea>
          </div>

          <div>
            <button onClick={handleDencrypt}>descriptografar</button>
            <textarea value={load_dataDencrypted}></textarea>
            <button onClick={load}>Load</button>
          </div>
        </>
      )}
    </div>
  );
};
