import React, { useState, useEffect } from 'react';
import './App.css';
import { useCrypto } from './providers/CryptoProvider';
import { DownloadData } from './DownloadData';
import { Applications } from './components/Applications';

import { useParams } from 'react-router-dom';
import { AppTable } from './components/AppTable/AppTable';
const App = () => {
  const {
    encryptData,
    decryptData
  } = useCrypto();
  const [website, setWebsite] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [secret, setSecret] = useState('');
  const [passwords, setPasswords] = useState([]);
  const [alertVisible, setAlertVisible] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [_, setShowPassword] = useState(false);

  const [createNew,set_createNew] = useState()
  const [showUser, set_showUser] = useState(false);
  const [typeAlert, set_typeAlert] = useState()
  const { id } = useParams()

  useEffect(() => {
    showPasswords();
  }, []);

  const copyPassword = async (pass) => {
    try {

      const textArea = document.createElement('textarea');
      textArea.value = decryptData(pass, secret);

      if (textArea.value) {
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        set_typeAlert('success');
      } else {
        set_typeAlert('error');
      }

      setAlertVisible(true);

      setTimeout(() => {
        setAlertVisible(false);
      }, 2000);
    } catch (error) {
      console.error('Error copying text:', error);
    }
  };

  const deletePassword = (app) => {

    const confirm = window.confirm("Confirm delete?")

    if (confirm) {
      const updatedPasswords = passwords.filter((e) => {
        debugger
        return (e.username !== app.username) && (e.password !== app.password)
      });
      setPasswords(updatedPasswords);
      localStorage.setItem(`app_${id}`, JSON.stringify(updatedPasswords));
      alert(`Successfully deleted`);
    }
  };

  const showPasswords = () => {
    setPasswords([]);
    setWebsite('');
    setUsername('');
    setPassword('');
    setEditing(false);
    setEditIndex(null);
    setShowPassword(false);
  };

  const savePassword = () => {
    if (!website || !username || !password || !secret) {
      alert('Please fill in all fields.');
      return;
    }


    const enc_pass = encryptData(password, secret)

    if (editing && editIndex !== null) {
      const updatedPasswords = [...passwords];
      updatedPasswords[editIndex] = { website, username, password: enc_pass };
      setPasswords(updatedPasswords);
      setEditing(false);
      setEditIndex(null);
      setWebsite('');
      setUsername('');
      setPassword('');
      //  localStorage.setItem(`app_${id}`, JSON.stringify(updatedPasswords));
    } else {
      const newPassword = { website, username, password: enc_pass };

      setPasswords((prevPasswords) => {
        // old =[...prevPasswords, newPassword]
        return [...prevPasswords, newPassword]
      });
      setWebsite('');
      setUsername('');
      setPassword('');
    
    }
    set_createNew(false)
  };

  const editPassword = (index) => {

    const password = decryptData(passwords[index].password, secret)

    if(password){
      setEditing(true);
      setEditIndex(index);
      setWebsite(passwords[index].website);
      setUsername(passwords[index].username);
      setPassword(password);
      set_createNew(true)
    }else{
      setAlertVisible(true)
      set_typeAlert('error')
      
      setTimeout(() => {
        setAlertVisible(false);
      }, 2000);
    }
    
  };


  const toggleUserVisibility = () => {
    set_showUser((user) => !user);
  };
  useEffect(() => {
    const loadPass = localStorage.getItem(`app_${id}`);

    if (loadPass) {
      const data = JSON.parse(loadPass)
      if (data) {

        if (typeof data === 'object' && !data[0]) {
          setPasswords(data);
        } else {
          setPasswords(data);
        }

      }

      // setPasswords(JSON.parse(locaPass));
    }
  }, [])

  useEffect(() => {
    
    if (passwords && passwords.length && JSON.stringify(passwords) !== passwords) {
      localStorage.setItem(`app_${id}`, JSON.stringify(passwords));
    }
  }, [passwords])
  

  return (
    <div className="container">
      <Applications>

      </Applications>
      <div className="content">
        <h2 className="subHeading">
          {alertVisible && <>
            {typeAlert === "error" && <>
              <span id="alert-error">(Error!)</span>
            </>}
            {typeAlert !== "error" && <>
              <span id="alert">(Copied!)</span>
            </>}
          </>

          }
        </h2>

        {!createNew&&<>
          {passwords && passwords.length === 0 ? (
          <p className="noData">No Data To Show</p>
        ) : (
          <div className="table">
            <AppTable showUser={showUser} toggleUserVisibility={toggleUserVisibility} passwords={passwords} editPassword={editPassword} deletePassword={deletePassword} copyPassword={copyPassword} secret={secret} />
          </div>
        )}
        
        </>}
      


      {!createNew && <>

          <input

            placeholder="Secret Password"
            type="password"
            value={secret}
            onChange={(e) => setSecret(e.target.value)}
          />

          <button
          onClick={()=>{
            set_createNew(true)
          }}
          >NEW</button>
        </>}

        {createNew && <>
          <h2 className="subHeading">{editing ? 'Edit Password' : 'Add a Password'}</h2>
          <input

            placeholder="Website"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
          />
          <input

            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input

            placeholder="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <input

            placeholder="Secret"
            type="password"
            value={secret}
            onChange={(e) => setSecret(e.target.value)}
          />
          <button onClick={savePassword}>
            <span className="submitButtonText">
              {editing ? 'Update Password' : 'Add Password'}
            </span>
          </button>
        </>}

      </div>
      <DownloadData secret={secret} />

    </div>
  );
};

export default App;
