import React, { useEffect, useState } from "react"
import { useParams } from 'react-router-dom';


import './Applications.style.css'
import { Application } from "./Application"

export const Applications = () => {
    const [applications, set_applications] = useState([])

    const { id } = useParams()

    const [confirme_delete, set_confirme_delete] = useState(false)
    const [creating, set_creating] = useState(false)
    const [name, set_name] = useState()
    const [description, set_description] = useState()
    const [appInfo, set_appInfo] = useState(null)
    function uuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    const addApplications = (app) => {
        if (id) {
            window.location.href = "/"
            return;
        }
        if (!creating) {
            set_creating(true);
            return;
        }
        if (!description || !name) {
            set_creating(false);
            return
        }
        app['id'] = uuid()
        app['name'] = name
        app['description'] = description
        const applications = localStorage.getItem('applications')
        var apps = []
        if (applications) {
            apps = JSON.parse(applications)
        }
        apps.push(app)
        localStorage.setItem('applications', JSON.stringify(apps))
        set_applications(apps)
        goto(app.id)
    }

    const isCancelable = () => {
        return (name || description) && (!name || !description)
    }
    const goto = (id) => {

        window.location.href = `/app/${id}`
    }
    const loadApplications = () => {

        const applications = localStorage.getItem('applications')
        const apps = JSON.parse(applications)
        if (applications) {
            set_applications(apps)
        }

        if (id && applications.length) {
            const info = apps.find((app) => app.id == id)
            set_appInfo(info)
        }
    }

    const confirmDelete = () => {
        const applications = localStorage.getItem('applications')
        var apps = []
        if (applications) {
            apps = JSON.parse(applications)
        }

        const newApps = apps.filter((app) => {
            return app.id != id
        })
        localStorage.setItem('applications', JSON.stringify(newApps))
        window.location.href = "/"
    }

    const loadInfo = () => {

    }
    useEffect(() => {
        loadApplications()
    }, [])


    return <React.Fragment >

        {id &&
            <div>
                <button
                    onClick={() => {
                        set_confirme_delete(!confirme_delete)
                    }}
                >Delete</button>

                {confirme_delete && <>
                    <button
                        onClick={() => {
                            confirmDelete()
                        }}
                    >Confirm delete</button>
                </>}
            </div>}


        <div className="applications">
            {<React.Fragment>
                <div onClick={() => addApplications({})}>
                    {id && (<>
                        <Application
                            title={appInfo?.name}
                            description={appInfo?.description}

                        >

                            <button>Voltar</button>

                        </Application>


                    </>)}
                    {!id && (
                        <>
                            {(name && description) ? <>
                                <Application
                                    title=""
                                    description="APPLY"

                                />
                            </> : <>
                                {isCancelable() && <>
                                    <Application
                                        title=""
                                        description="CANCEL"

                                    />
                                </>}
                                {!isCancelable() && <>
                                    <Application
                                        title=""
                                        description="+ ADD"

                                    />
                                </>}
                            </>}

                        </>
                    )}
                </div>
            </React.Fragment>}
            {!creating && !id && applications.map((app) => {
                return (<React.Fragment key={app.id}>
                    <div onClick={() => goto(app.id)}>
                        <Application
                            title={app.name}
                            description={app.description}

                        />
                    </div>

                </React.Fragment>)
            })}

            {creating && (
                <React.Fragment>
                    <input value={name} onChange={(e) => {
                        set_name(e.currentTarget.value)
                    }} placeholder="Nome aplicação" />
                    <input value={description} onChange={(e) => {
                        set_description(e.currentTarget.value)
                    }} placeholder="Descrição" />
                </React.Fragment>
            )}
        </div>

    </React.Fragment>
}