import './AppTable.style.css'

export const AppTable = ({ passwords, copyPassword, deletePassword, editPassword, secret, toggleUserVisibility, showUser }) => {

  if(!passwords || !passwords.length){
    return<></>
  }
  const AppList = passwords;
  return (
    <div className="app-table-container">
      <table className="app-table">
        <thead>
          <tr>
            <th>Website</th>
            <th>Username</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {AppList && AppList.length && AppList.map((app, index) => {

            if(!app.website){
              return<>{JSON.stringify(AppList)}</>
            }
            return (
              <tr key={index}>
                <td>{app.website}</td>
                <td>
                {showUser ? app.username : app.username.replace(/./g, '*')}
                  <button style={{float:'right'}}onClick={toggleUserVisibility}>
                    {showUser ? 'Hide' : 'Show'}
                  </button>
                </td>
  
                <td>
                  <button onClick={() => editPassword(index)}>Edit</button>
                  <button onClick={() => deletePassword(app)}>Delete</button>
                  <button onClick={() => copyPassword(app.password, secret)}>Copy</button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  );
};