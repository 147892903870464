import React, { Children, useEffect, useState } from "react"

import './Applications.style.css'

export const Application = ({
    title,
    description,
    children
}) => {

    return <React.Fragment >
        <div className="new-application">
            <b>{title}</b>
            <p>{description}</p>
            {children}
        </div>
    </React.Fragment>
}