import React, { createContext, useContext } from 'react';
import CryptoJS from 'crypto-js';

// Criar um contexto para o provedor
const CryptoContext = createContext();

// Provedor (Provider) que encapsula as funções de criptografia
export const CryptoProvider = ({ children }) => {
  // Função para encriptar dados
  const encryptData = (data, password) => {
    const ciphertext = CryptoJS.AES.encrypt(data, password);
    return ciphertext.toString();
  };

  // Função para descriptar dados
  const decryptData = (encryptedData, password) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, password);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  };

  // Disponibilizar as funções no contexto
  const contextValue = {
    encryptData,
    decryptData,
  };

  return (
    <CryptoContext.Provider value={contextValue}>
      {children}
    </CryptoContext.Provider>
  );
};

// Hook de uso para acessar as funções de criptografia
export const useCrypto = () => {
  const context = useContext(CryptoContext);
  if (!context) {
    throw new Error('useCrypto deve ser usado dentro de um CryptoProvider');
  }
  return context;
};
